export const BASKET_TIME_OUT_THRESHOLD_MS = 30 * 60 * 1000;

export const DEFAULT_PAGINATION_PARAMS = {
  start: 0,
  limit: 10,
  max_cart_multiple: 4,
};

export const CHILD_LIST_DIV_ID = 'child-list-div';

export enum CheckoutMode {
  UNSPECIFIED = 'unspecified',
  SPECIFIC_CHILDREN = 'specific-children',
  BULK_DONATION = 'bulk-donation',
}

// NOTE :: Keep in-sync with src/mixins.scss::$breakpoints values
export const layoutBreakpoints = {
  S: 320,
  M: 481,
  L: 769,
};

export enum GoogleAnalyticsDonationTypeName {
  SPECIFIC_CHILDREN = 'Specific Child Donation',
  BULK_DONATION = 'Bulk Donation',
}