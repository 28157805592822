import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../common/button/pf-button';
import { OrnamentBlue, OrnamentRed } from '../../constants/images';
import { getAuthReduxState } from '../../pages/auth';
import { getChildrenReduxState } from '../../pages/children';
import RandomChildImage from '../random-child-image/random-child-image';
import { GAChooseAChildButtonClickTracking, GAExpressSponsorShipButtonClickTracking } from '../../utils';

export interface IProps {
  setIsExpressSponsorshipModalVisible: VoidFunction;
  scrollToChildrenList: VoidFunction
}

export const SubHeader: React.FC<IProps> = ({
  setIsExpressSponsorshipModalVisible,
  scrollToChildrenList,
}) => {
  const { orgInfo, loading } = useSelector(getAuthReduxState);
  const { childDonationAmount, isNationalOrg } = orgInfo || ({} as any);
  const { basket = [], getChildrenLoading } = useSelector(getChildrenReduxState);
  const isBasketEmpty = basket.length > 0;

  const subHeaderImage = <RandomChildImage className="image-hero-box__image" />;

  return (
    <>
      <div className={'sub-header-container'}>
        <div className="header-text-box">
          {!isNationalOrg && (
            <p className="heading-text__org-name">{orgInfo.orgName}</p>
          )}
          <p className="heading-text">
            Make sure every child <br />
            feels loved this year
          </p>
          <p className="heading-text-two">
            Deliver gifts and the Gospel to children <br />
            with a parent in prison for only ${childDonationAmount}
          </p>
          <div className="button-container">
            <Button
              isDisabled={getChildrenLoading}
              onClick={() => {
                scrollToChildrenList();
                GAChooseAChildButtonClickTracking();
              }}
            >
              Choose a Child
            </Button>
          </div>
          <button
            className="express-sponsorship-button"
            disabled={isBasketEmpty}
          >
            <p
              className={
                isBasketEmpty
                  ? 'heading-text-three'
                  : 'heading-text-three--disabled'
              }
              onClick={() => {
                setIsExpressSponsorshipModalVisible();
                GAExpressSponsorShipButtonClickTracking();
              }}
            >
              Or choose the number of children you wish to sponsor
            </p>
          </button>
        </div>
        <div className="header-image-box">
          <div
            className={
              isNationalOrg
                ? 'image-hero-box'
                : 'image-hero-box image-hero-box--lowered'
            }
          >
            {!loading && subHeaderImage}
            {!loading && subHeaderImage && (
              <div className="bless-a-child">
                <div className="bless-a-child__sticker">
                  <p>
                    BLESS A <br /> CHILD FOR <br />
                  </p>
                  <p className="donation-amount">${childDonationAmount}</p>
                </div>
              </div>
            )}
          </div>
          <div className="ornament-box">
            <div className="red-ornament">
              {!loading && subHeaderImage && <OrnamentRed />}
            </div>
            <div className="blue-ornament">
              {!loading && subHeaderImage && <OrnamentBlue />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubHeader;
