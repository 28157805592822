export const Env = {
  REACT_APP_API_BASE: process?.env?.REACT_APP_API_BASE || '',
  REACT_APP_BASE: process?.env?.REACT_APP_BASE || '#',
  IS_STATIC_DONATION_SITE_REDIRECT_ENABLED: process?.env
    ?.REACT_APP_IS_STATIC_DONATION_SITE_REDIRECT_ENABLED
    ? process.env.REACT_APP_IS_STATIC_DONATION_SITE_REDIRECT_ENABLED.toLowerCase() ===
      'true'
    : true,
    REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID: process?.env?.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ? process?.env?.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID : 'placeholder-tracking-id'
};
