import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, map } from 'lodash';
import { Col, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { CheckoutMode, Gift1, Gift2, Gift3, layoutBreakpoints, OrnamentBlue, OrnamentRedShort, ROUTES } from '../../constants';
import {
  getChildrenReduxState,
  ON_REMOVE_ITEM_FROM_BASKET,
  ON_SET_CHECKOUT_MODE,
} from '../children';
import { getAuthReduxState } from '../auth';
import { GAAddAnotherChildButtonClickTracking, GAContinueToPaymentButtonClickTracking, GARemoveFromBasketButtonClickTracking, useCalSum, useNavIfBasketEmpty, useWindowDimensions } from '../../utils';
import { IGift } from '../../types';
import Button from '../../components/common/button/pf-button';
import GiftIcon from '../../components/gift-icon/gift-icon';

export interface IReviewBasketProps extends RouteComponentProps {}

const ReviewBasket: React.FC<IReviewBasketProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { basket = [], checkoutMode } = useSelector(getChildrenReduxState);

  const { orgInfo } = useSelector(getAuthReduxState);
  const { childDonationAmount } = orgInfo;

  const isBulkDonation = checkoutMode === CheckoutMode.BULK_DONATION;
  const { width } = useWindowDimensions();
  const isMobileLayout = width < layoutBreakpoints.L;
  const childrenPerSingleTag = 1

  useNavIfBasketEmpty(basket);
  const total = useCalSum(orgInfo, basket);

  const donationTotal = isBulkDonation ? basket?.[0]?.bulkDonationTotal : total.toFixed?.(2)

  const onRemoveFromBasket = (gift: IGift) => {
    if (
      (checkoutMode === CheckoutMode.SPECIFIC_CHILDREN && basket.length <= 1) ||
      checkoutMode === CheckoutMode.BULK_DONATION
    ) {
      dispatch(ON_SET_CHECKOUT_MODE(CheckoutMode.SPECIFIC_CHILDREN));
    }

    dispatch(
      ON_REMOVE_ITEM_FROM_BASKET({
        data: gift,
        isReviewBasket: true,
      })
    );
  };

  const styledDonationTotal = isMobileLayout ? (
    <p className='total-amount-text'>
      Total: {`$${donationTotal}`}
    </p>
  ) : (
    <p className='total-amount-text'>
      Total: <br/> {`$${donationTotal}`}
    </p>
  );

  const ornamentWidth = width < layoutBreakpoints.M ? 50 : 100

  const renderAutoSelect = (bulkDonationTotal) => {
    const bulkNumChildren = Math.floor(bulkDonationTotal / childDonationAmount);
    const randomGiftIconId = Math.floor(Math.random() * 3); // number of gift images

    return (
      <div className='child-donation-item-container'>
          <div className="child-donation-list-row" key={1}>
            <GiftIcon id={randomGiftIconId} />
            <div className="tag-item-column">
              <p>
                Donation
              </p>
            </div>
            <div className="children-per-tag-column">
              <p>
                {bulkNumChildren}
              </p>
            </div>
            <div className="price-column">
              <p className="">
                {bulkDonationTotal} 
              </p>
            </div>
          </div>
        <button className='close-button-container'>
          <p 
            className='close-button'
            onClick={() => onRemoveFromBasket(bulkDonationTotal)}>
            x
          </p>
        </button>
      </div>
    );
  };
  const _renderRBPCardContent = () => {
    return (
      <>
        {isEmpty(basket) ? (
          <div className="empty-basket">Basket is empty!</div>
        ) : (
          <>
            <div className="child-donation-list-container">
              {map(basket, (item, key) => {
                const { child, bulkDonationTotal } = item || { child: {} };

                if (!child && bulkDonationTotal) {
                  return renderAutoSelect(bulkDonationTotal);
                } else if (!child) {
                  history.push(ROUTES.CHILDREN.path);
                  return;
                }

                return (
                  <div className='child-donation-item-container'>
                    {isMobileLayout && <button className='close-button-container'>
                      <p 
                        className='close-button'
                        onClick={() => {
                          onRemoveFromBasket(item);
                          GARemoveFromBasketButtonClickTracking();
                        }}>
                        x
                      </p>
                    </button>}
                      <div className="child-donation-list-row" key={key}>
                        <div className="tag-item-column">
                          <GiftIcon id={child.id} />
                          <p>
                            {child.firstName}
                          </p>
                        </div>
                        <div className="children-per-tag-column">
                          <p>
                            {childrenPerSingleTag}
                          </p>
                        </div>
                        <div className="price-column">
                          <p className="">
                            {`$${childDonationAmount || 25}`}
                          </p>
                        </div>
                      </div>
                    {!isMobileLayout && <button className='close-button-container'>
                      <p 
                        className='close-button'
                        onClick={() => {
                          onRemoveFromBasket(item);
                          GARemoveFromBasketButtonClickTracking();
                        }}>
                        x
                      </p>
                    </button>}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="review-basket-container">
        <div className="review-basket-header-container">
          <div className='review-basket-header'>
            <p>These kids will have a great Christmas, thanks to you!</p>
            <div className='ornament-container'>
              <div className='red-ornament'>
                <OrnamentRedShort style={{width: ornamentWidth}}/>
              </div>
              <div className='blue-ornament'>
                <OrnamentBlue style={{width: ornamentWidth}}/>
              </div>
            </div>
          </div>
        </div>
        <div className='review-basket-tag-label-container'>
          <div className='review-basket-tag-label-box'>
            <div className='review-basket-tag-label-box__label-one'><p>Item</p></div>
            <div className='review-basket-tag-label-box__label-two'><p>Number of Children</p></div>
            <div className='review-basket-tag-label-box__label-three'><p>Price</p></div>
          </div>
          <div className='review-basket-contents'>
            {_renderRBPCardContent()}
            <div className='review-basket-payment-box'>
              {styledDonationTotal}
              <div className='button-box'>
                <Button 
                  styleOverride={{width: '70%', paddingRight: '1vw'}} 
                  onClick={() => {
                    history.push(ROUTES.PAYMENT.path);
                    GAContinueToPaymentButtonClickTracking();
                  }}>
                    Continue to Payment
                </Button>
              </div>
              {!isBulkDonation && <p 
                className='add-child-text'
                onClick={() => {
                  history.push(ROUTES.CHILDREN.path);
                  GAAddAnotherChildButtonClickTracking();
                }}
                >
                Add another child
              </p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewBasket;
