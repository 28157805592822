import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { layoutBreakpoints, ROUTES } from '../../constants';
import { IconCart, AngelTreeLogo } from '../../constants/images';
import Button from '../common/button/pf-button';
import { getChildrenReduxState } from '../../pages/children';
import { GACompleteGiftButtonClickTracking, GAReturnToSPonsorShipPageButtonClickTracking, useWindowDimensions } from '../../utils';

interface IHeaderProps {
  currentRoutePath: string;
}

export const Header: React.FC<IHeaderProps> = ({ currentRoutePath }) => {
  const { basket = [], numUndonatedGifts } = useSelector(getChildrenReduxState);
  const dispatch = useDispatch();

  const isBasketEmtpy = basket.length < 1;
  const numUndonatedGiftsDisplayValue =
    numUndonatedGifts > 0 ? numUndonatedGifts : ''; // So the default doesn't display while the page is loading data

  const { width } = useWindowDimensions();
  const isMobileLayout = width < layoutBreakpoints.L;

  const isChildScreenWithMobileLayout =
    currentRoutePath === ROUTES.CHILDREN.key && isMobileLayout;

  // Review screen only has logo displayed in header; center it in that case
  const headerStyleDecorator =
    currentRoutePath === ROUTES.REVIEW_BASKET.path
      ? 'header--center-content'
      : '';

  // The "Header" becomes a footer only on the child selection screen, if on mobile layout
  const headerStyle = isChildScreenWithMobileLayout
    ? `header header--as-footer`
    : `header ${headerStyleDecorator}`;

  const onHandleCheckout = () => {
    if (!isBasketEmtpy) {
      dispatch(push(ROUTES.REVIEW_BASKET.path));
    }
  };

  const getNavElement = () => {
    if (currentRoutePath === ROUTES.CHILDREN.path) {
      if (isMobileLayout) {
        return (
          <button
            className="cart-button--mobile"
            onClick={() => {
              onHandleCheckout();
              GACompleteGiftButtonClickTracking();            
            }}
            disabled={isBasketEmtpy}
          >
            <div className="cart-indicator">
              <IconCart className="cart-indicator__icon" />
              <div className="cart-indicator__notification-badge">
                {basket.length}
              </div>
            </div>
          </button>
        );
      }
      return (
        <Button
          onClick={() => {
            onHandleCheckout();
            GACompleteGiftButtonClickTracking();
          }}
          isDisabled={isBasketEmtpy}
          styleVariant="transparent-light"
        >
          <div className="cart-indicator">
            <IconCart className="cart-indicator__icon" />
            <div className="cart-indicator__notification-badge">
              {basket.length}
            </div>
          </div>
          COMPLETE GIFT
        </Button>
      );
    } else if (currentRoutePath === ROUTES.PAYMENT.path) {
      return (
        <button
          className="nav-link"
          type="button"
          onClick={() => {
            dispatch(push(ROUTES.CHILDREN.path));
            GAReturnToSPonsorShipPageButtonClickTracking();
          }}
        >
          Return to Sponsorship page
        </button>
      );
    } else if (currentRoutePath === ROUTES.EXIT.path) {
      if (isMobileLayout) {
        return (
          <button
            className="nav-link"
            type="button"
            onClick={() => {
              dispatch(push(ROUTES.CHILDREN.path));
            }}
          >
            BACK TO HOME
          </button>
        );
      }
      return (
        <Button
          onClick={() => {
            dispatch(push(ROUTES.CHILDREN.path));
          }}
          styleVariant="transparent-light"
        >
          BACK TO HOME
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {isChildScreenWithMobileLayout && (
        <div className={`header-mobile`}>
          <AngelTreeLogo className="angel-tree-logo" />
        </div>
      )}
      <div className={headerStyle}>
        {!isChildScreenWithMobileLayout && (
          <AngelTreeLogo className="angel-tree-logo" />
        )}
        {currentRoutePath === ROUTES.CHILDREN.key && (
          <p className="header__title">
            <span>
              {numUndonatedGiftsDisplayValue.toLocaleString()}
            </span>{' '}
            Angel Tree children still need a sponsor
          </p>
        )}
        {getNavElement()}
      </div>
    </>
  );
};

export default Header;
