import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import PfCheckbox from '../common/checkbox';
import { EGender, EGenderMap } from '../../redux/refdata.redux';

interface Props {
  isBoyChecked: boolean;
  isGirlChecked: boolean;
  handleBoyClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  handleGirlClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  genders: EGender[];
  isDisabled: boolean;
}

export const GenderFilter = (props: Props) => {
  const {
    isBoyChecked,
    isGirlChecked,
    handleBoyClick,
    handleGirlClick,
    genders,
    isDisabled,
  } = props;
  return (
    <FormGroup className="gender-filter">
      {genders.map((gender) => (
        <PfCheckbox
          isDisabled={isDisabled}
          isChecked={
            gender.toString() === EGender[EGender.M]
              ? isBoyChecked
              : isGirlChecked
          }
          onChange={(e, t) =>
            gender.toString() === EGender[EGender.M]
              ? handleBoyClick(e, t)
              : handleGirlClick(e, t)
          }
          key={gender.toString()}
          label={EGenderMap[gender] || ''}
        />
      ))}
    </FormGroup>
  );
};

export default GenderFilter;
