import React from 'react';
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import {
  PfCheckboxChecked,
  PfCheckboxUnchecked,
} from '../../../constants/images';

interface IPfCheckboxProps {
  isChecked?: CheckboxProps['checked'];
  isDisabled?: CheckboxProps['disabled'];
  onChange?: CheckboxProps['onChange'];
  label?: FormControlLabelProps['label'];
  key?: React.Attributes['key'];
}

/**
 * Wraps MUI Checkbox, but applies our desired application style(s). Extend implementation as necessary.
 *
 * NOTE :: Internal component container is an MUI `FormControlLabel`, therefore it is expected for usage of this component
 * to be wrapped inside an MUI `FormGroup` component if grouping .
 */
export const GenderFilter: React.FC<IPfCheckboxProps> = ({
  isChecked,
  isDisabled,
  onChange,
  label = null,
  key,
}) => {
  return (
    <FormControlLabel
      className="checkbox-container"
      disabled={isDisabled}
      key={key}
      control={
        <Checkbox
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
          icon={<PfCheckboxUnchecked />}
          checkedIcon={<PfCheckboxChecked />}
        />
      }
      label={label}
    />
  );
};

export default GenderFilter;
