import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA4 from 'react-ga4';


export const GAPageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA4.send({ hitType: 'pageview', page:location.pathname })
  }, [location]);
};

export const GAPaymentForNationalOrgButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "'Donate' to National Org Button Click",
  })
};

export const GAPaymentFoPersonalizedURLButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "'Donate' to Personalized Church Org Button Click",
  })
};

export const GAReturnToSPonsorShipPageButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "'Return to Sponsorship page' from Payment Info page Button Click",
  })
};

export const GAChooseAChildButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "'Choose a Child' Button Click",
  })
};

export const GAExpressSponsorShipButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "Express Sponsorship Button Click",
  })
};

export const GASponsorChildButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "'Sponsor Me' Button Click",
  })
};

export const GADeselectChildButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "'Deselect' Child Button Click",
  })
};

export const GACompleteGiftButtonClickTracking = () => {
  ReactGA4.event({
    category: "button",
    action: "Button Click",
    label: "'Complete Gift'/Cart Icon Button Click",
  })
};

export const GAContinueToPaymentButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "'Continue to Payment' from Review Basket Button Click"
  })
};

export const GAAddAnotherChildButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "Review Basket 'Add Another Child' Button Click"
  })
};

export const GARemoveFromBasketButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "Review Basket 'X' Remove Child Button Click"
  })
};

export const GALoadMoreChildrenButtonClickTracking = () => {
  ReactGA4.event({
    category: "Button",
    action: "Button Click",
    label: "'Show Me More' Children Button Click"
  })
};

export const GAFilterByAgeSliderTracking = () => {
  ReactGA4.event({
    category: "Filter",
    action: "Filter Results",
    label: "'Search by Age' Filter Change"
  })
};

export const GAFilterByBoyGenderTracking = () => {
  ReactGA4.event({
    category: "Filter",
    action: "Filter Results",
    label: "'Search by gender' Boy Filter Change"
  })
};

export const GAFilterByGirlGenderTracking = () => {
  ReactGA4.event({
    category: "Filter",
    action: "Filter Results",
    label: "'Search by gender' Girl Filter Change"
  })
};

export const GARevenueTracking = (donationAmount: number, submissionId: string, googleAnalyticsPaymentItems: Array<{}>) => {
  const formattedGAEventValue = Number(donationAmount).toFixed(2)
  ReactGA4.event('purchase', {
    currency: 'USD',
    value: formattedGAEventValue,
    transaction_id: submissionId,
    items: googleAnalyticsPaymentItems
  })
}
