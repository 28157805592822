import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ReactGA4 from 'react-ga4'
import { Loading } from './components/common';
import { ScrollToTop } from './components';
import { ROUTES } from './constants';
import './index.scss';
import { getAuthReduxState } from './pages/auth';
import { ROUTE_COMPONENTS, RouteWrapper } from './routes';
import { Offline } from './pages/offline/offline';
import { getRefDataState } from './selectors/refdata.selector';
import { GAPageTracking, redirectToStaticDonationSite } from './utils';
import { Env } from './utils';

const getFaviconEl = (): HTMLLinkElement => {
  return document.getElementById('favicon') as HTMLLinkElement;
};

const GOOGLE_ANALYTICS_TRACKING_ID = Env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID

const App = () => {
  useEffect(() => {
    const favicon = getFaviconEl();
    const isLocal = window.location.hostname === 'localhost';
    favicon.href = `${
      isLocal ? '/' : process.env.PUBLIC_URL
    }favicon.ico`;
  }, []);
  
  // Initialize GA 
  useEffect(() => {
    ReactGA4.initialize(GOOGLE_ANALYTICS_TRACKING_ID, {
      gaOptions: {
        debug_mode: true,
      }
    });
  }, []);

  // Track route changes
  GAPageTracking();

  const { loading = false } = useSelector(getAuthReduxState);
  const refData = useSelector(getRefDataState);

  const { programYear } = useSelector(getRefDataState).appSettings;
  let currentDateTime = new Date();
  const appNotAvailable =
    (currentDateTime.getFullYear() === programYear &&
      currentDateTime.getMonth() === 11 &&
      ((currentDateTime.getDate() === 25 && currentDateTime.getHours() >= 3) ||
        currentDateTime.getDate() >= 26)) ||
    currentDateTime.getFullYear() !== programYear;

  if (!loading && appNotAvailable) {
    redirectToStaticDonationSite();
  }

  const isAppOffline =
    refData.hasBeenRequested &&
    !refData.isLoading &&
    !refData.appSettings.programYear;

  if (isAppOffline) {
    return <Offline />;
  }

  const initialRouteDetails = refData.appSettings.areVatAccessCodesEnabled
    ? { props: { ...ROUTES.AUTH }, component: ROUTE_COMPONENTS.AUTH }
    : { props: { ...ROUTES.CHILDREN }, component: ROUTE_COMPONENTS.CHILDREN };

  return (
    <>
      {(loading || refData.isLoading) && <Loading fullPage></Loading>}
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path="/:orgId/:page"
            component={(props: RouteComponentProps<{}>) => (
              <Switch>
                {Object.keys(ROUTES).map((key) => {
                  const routeProps = {
                    ...props,
                    ...(ROUTES[key] || {}),
                    component: ROUTE_COMPONENTS[key],
                    key,
                  };
                  return (
                    <RouteWrapper
                      {...routeProps}
                      path={`/:orgId/${routeProps.path}`}
                    />
                  );
                })}
              </Switch>
            )}
          />
          <RouteWrapper
            {...initialRouteDetails.props}
            component={initialRouteDetails.component}
            path="/"
            exact
          />
          <RouteWrapper
            {...initialRouteDetails.props}
            component={initialRouteDetails.component}
            path="/:orgId"
            exact
          />
          <Redirect from="*" exact to="/:orgId" />
        </Switch>
      </ScrollToTop>
    </>
  );
};

export default App;
