import React, { useEffect, useState } from 'react';
import Slider from '@material-ui/core/Slider';
import _ from 'lodash';

interface IAgeSliderProps {
  onChangeCommitted?: (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) => void;
  ageRange: number[];
  isDisabled?: boolean;
}

const MIN_VALUE = 0;
const MAX_VALUE = 18;

const AgeSlider = (props: IAgeSliderProps) => {
  const { onChangeCommitted, ageRange, isDisabled } = props;
  const [value, setValue] = useState<number | number[]>([MIN_VALUE, MAX_VALUE]);

  useEffect(() => {
    if (ageRange?.length) {
      setValue([ageRange[0], ageRange[1]]);
    }
  }, [ageRange]);

  const onSliderChange = (
    _event: React.ChangeEvent<{}>,
    item: number | number[]
  ) => {
    setValue(item);
  };

  return (
    <div className="age-search">
      <label className="age-search__mark">{MIN_VALUE}</label>
      <Slider
        disabled={isDisabled}
        className="age-search__slider"
        getAriaLabel={(index) => `age-slider-${index}`}
        value={value}
        valueLabelDisplay="auto"
        onChange={onSliderChange}
        onChangeCommitted={(event, items) =>
          onChangeCommitted && onChangeCommitted(event, items)
        }
        min={MIN_VALUE}
        max={MAX_VALUE}
      />
      <label className="age-search__mark">{MAX_VALUE}</label>
    </div>
  );
};
export default AgeSlider;
