import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forEach, isEmpty } from 'lodash';
import { IoMdLock } from 'react-icons/io';
import {
  CheckoutMode,
  layoutBreakpoints,
  DesktopPaymentScreenImage,
  PAYMENT_HEADER_IMAGES,
  TEXT_CONSTANTS,
  SecureDonation,
  AccreditedCharitySeal,
  CfcSeal,
  EcfaAccreditedSeal,
  GuidestarPlatinumSeal,
  CreditButton,
  BankButton,
  GoogleAnalyticsDonationTypeName,
} from '../../constants';
import { ON_SPONSOR_GIFT } from './payment.redux';
import {
  toastError,
  useCalSum,
  useNavIfBasketEmpty,
  useWindowDimensions,
  getCCExpirationYears,
  MONTHS_DROPDOWN_ITEMS,
  accountTypes,
  getDropdownOptions,
  GAPaymentFoPersonalizedURLButtonClickTracking,
  GAPaymentForNationalOrgButtonClickTracking,
} from '../../utils';
import { getChildrenReduxState } from '../children';
import { getAuthReduxState } from '../auth';
import { getPaymentReduxState } from './payment.selector';
import { IObj, IState } from '../../types';
import { GET_STATES_DATA } from '../../redux/states.redux';
import FormGroup from '@material-ui/core/FormGroup';
import PfCheckbox from '../../components/common/checkbox';
import { DonationEntityTypeId } from '../../redux/refdata.redux';
import { getStatesState } from '../children/children';
import Button from '../../components/common/button/pf-button';

const Payment: React.FC = () => {
  const dispatch = useDispatch();

  const { basket = [], checkoutMode } = useSelector(getChildrenReduxState);
  const { width } = useWindowDimensions();
  const isMobileLayout = width <= layoutBreakpoints.L;
  const { states = [] } = useSelector(getStatesState);
  const { orgInfo } = useSelector(getAuthReduxState);
  const { accessCode = '', isPaymentError = false } =
    useSelector(getPaymentReduxState);

  const dropdownStates = getDropdownOptions(states, 'states');

  const [showCCInputs, setShowCCInputs] = useState(true);
  const { isNationalOrg, childDonationAmount } = orgInfo;
  const isBulkDonation = checkoutMode === CheckoutMode.BULK_DONATION;
  const total = useCalSum(orgInfo, basket);
  const donationTotal = isBulkDonation
    ? basket?.[0]?.bulkDonationTotal
    : total.toFixed?.(2);

  // credid card state values
  const [ccNumber, setCcNumber] = useState<number | null>(null);
  const [expirationMonth, setExpirationMonth] = useState<number | null>(null);
  const [expirationYear, setExpirationYear] = useState<number | null>(null);
  const [cvv, setCvv] = useState<number | null>(null);

  // bank account state values
  const [routingNumber, setRoutingNumber] = useState<string | null>(null);
  const [accountNumber, setAccountNumber] = useState<number | null>(null);
  const [confirmedAccountNumber, setConfirmedAccountNumber] = useState<
    number | null
  >(null);
  const [accountType, setAccountType] = useState<string | null>(null);
  const [bankName, setBankName] = useState<string | null>(null);
  const [accountName, setAccountName] = useState<string | null>(null);

  // personal infomration state values
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [address1, setAddress1] = useState<string | null>(null);
  const [address2, setAddress2] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [zipCode, setZipCode] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  const [donationEntityTypeId, setDonationEntityTypeId] = useState<number>(
    isNationalOrg
      ? DonationEntityTypeId.UNSPECIFIED
      : DonationEntityTypeId.PERSON
  );

  const expirationYears = getCCExpirationYears(expirationMonth);

  const [subHeaderImage, setSubHeaderImage] = useState<JSX.Element | null>(
    null
  );
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const hasGeneratedImage = useRef(false);

  const headerImages = useMemo(
    () =>
      PAYMENT_HEADER_IMAGES.map((src, index) => (
        <img key={index} src={src} alt={`Payment Header ${index}`} />
      )),
    []
  );

  const desktopHeaderImage = (
    <img src={DesktopPaymentScreenImage} alt={`Payment Header`} />
  );

  useEffect(() => {
    const generateSubHeaderImageIndex = (): JSX.Element => {
      const randomIndex = Math.floor(Math.random() * headerImages.length);
      return headerImages[randomIndex];
    };

    const backgroundImageIndex = generateSubHeaderImageIndex();
    backgroundImageIndex && setSubHeaderImage(backgroundImageIndex);
    hasGeneratedImage.current = true;
  }, []);

  useEffect(() => {
    if (isEmpty(states) && !isEmpty(orgInfo)) {
      if (!isEmpty(accessCode) || isNationalOrg) {
        dispatch(GET_STATES_DATA());
      }
    }
    if (isPaymentError) {
      toastError(TEXT_CONSTANTS.PAYMENT_ERROR_MSG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useNavIfBasketEmpty(basket);

  const onSelectCCTab = (value: boolean) => {
    setShowCCInputs(value);
    clearPaymentFields(value);
  };

  const clearPaymentFields = (showCCInputs: boolean) => {
    if (!showCCInputs) {
      setCcNumber(null);
      setExpirationMonth(null);
      setExpirationYear(null);
      setCvv(null);
    } else {
      setRoutingNumber(null);
      setAccountName(null);
      setConfirmedAccountNumber(null);
      setAccountType(null);
      setBankName(null);
      setAccountName(null);
    }
  };

  const handleInputs = (event: any) => {
    const { placeholder, value, id } = event.target;
    if (placeholder === 'Credit Card Number*') {
      setCcNumber(value);
    } else if (id === 'Month') {
      setExpirationMonth(value);
    } else if (id === 'Year') {
      setExpirationYear(value);
    } else if (placeholder === 'CVV*') {
      setCvv(value);
    } else if (placeholder === 'First Name*') {
      setFirstName(value);
    } else if (placeholder === 'Routing Number*') {
      setRoutingNumber(value);
    } else if (placeholder === 'Account Number*') {
      setAccountNumber(value);
    } else if (placeholder === 'Confirm Account Number*') {
      setConfirmedAccountNumber(value);
    } else if (id === 'Account Type') {
      setAccountType(value);
    } else if (placeholder === 'Bank Name*') {
      setBankName(value);
    } else if (placeholder === 'Account Name*') {
      setAccountName(value);
    } else if (placeholder === 'Last Name*') {
      setLastName(value);
    } else if (placeholder === 'Email Address*') {
      setEmail(value);
    } else if (placeholder === 'Address*') {
      setAddress1(value);
    } else if (placeholder === 'Address Line 2*') {
      setAddress2(value);
    } else if (placeholder === 'Zip/Postal Code*') {
      setZipCode(value);
    } else if (placeholder === 'City*') {
      setCity(value);
    } else if (id === 'State/Province*') {
      const selectedState = dropdownStates.find(
        (state) => state.value === value
      );
      const stateShortCode = (selectedState as IState)?.shortCode;
      setState(stateShortCode);
    }
  };

  const validatePayment = () => {
    if (showCCInputs) {
      if (!ccNumber) {
        toastError('Card Number is required.');
      } else if (!cvv) {
        toastError('CVV is required.');
      } else if (!expirationMonth || !expirationYear) {
        toastError('Expiration Date is required.');
      }
    } else if (!showCCInputs) {
      if (!routingNumber || routingNumber.length !== 9) {
        toastError('Enter valid routing number of 9 digits.');
      } else if (!accountNumber) {
        toastError('Enter valid account number.');
      } else if (accountNumber !== confirmedAccountNumber) {
        toastError('The account number must match.');
      } else if (!accountType) {
        toastError('Account tpe is required');
      } else if (!bankName) {
        toastError('Bank name is required.');
      } else if (!accountName) {
        toastError('Account name is required.');
      }
    }
    if (!firstName) {
      toastError('First Name is required.');
    } else if (!lastName) {
      toastError('Last Name is required.');
    } else if (!email) {
      toastError('Enter valid email address.');
    } else if (!address1) {
      toastError('Address Line 1 is required.');
    } else if (!city) {
      toastError('City is required.');
    } else if (!state) {
      toastError('State/Province is required.');
    } else if (!zipCode) {
      toastError('Enter valid ZIP/Postal code.');
    } else if (
      donationEntityTypeId === DonationEntityTypeId.UNSPECIFIED &&
      !isNationalOrg
    ) {
      toastError(
        'Please specify if you are donating as an individual or collective for the church.'
      );
    } else {
      onSubmitPayment();
    }
  };

  const onSubmitPayment = () => {
    var gaReportedDonationAmount = 0;
    var googleAnalyticsPaymentItems: Array<{}> = [];

    let payload: IObj = {
      first_name: firstName,
      last_name: lastName,
      mail: email,
      address: address1,
      address_line_2: address2,
      city: city,
      state: state,
      zip: zipCode,
      donation_entity_type_id: donationEntityTypeId,
    };

    if (isBulkDonation && basket?.[0]?.bulkDonationTotal) {
      payload = {
        ...payload,
        bulk_donation_total: parseInt(basket?.[0]?.bulkDonationTotal),
      };
      gaReportedDonationAmount = parseInt(basket?.[0]?.bulkDonationTotal);
      googleAnalyticsPaymentItems = [
        { item_name: GoogleAnalyticsDonationTypeName.BULK_DONATION },
      ];
    } else {
      const gift_ids: Array<number> = [];

      forEach(basket, (item, index) => {
        googleAnalyticsPaymentItems.push({
          item_id: `${index + 1}`,
          item_name: GoogleAnalyticsDonationTypeName.SPECIFIC_CHILDREN,
        });
        gift_ids.push(item.id);
      });

      payload = {
        ...payload,
        gift_ids,
        bulk_donation_total: undefined,
      };
      gaReportedDonationAmount = gift_ids.length * childDonationAmount;
    }

    if (showCCInputs) {
      payload = {
        ...payload,
        payment_type: 'CC',
        card_number: ccNumber,
        card_cvv: cvv,
        card_expiration_year: expirationYear,
        card_expiration_month: expirationMonth?.toString(),
      };
    } else {
      payload = {
        ...payload,
        payment_type: 'ACH',
        routing_number: routingNumber,
        account_number: accountNumber,
        bank_name: bankName,
        account_name: accountName,
        account_type: accountType,
      };
    }

    const googleAnanlyticsPurchaseEventPayload = {
      gaReportedDonationAmount,
      googleAnalyticsPaymentItems,
    };

    setIsPaymentProcessing(true);
    isNationalOrg
      ? GAPaymentForNationalOrgButtonClickTracking()
      : GAPaymentFoPersonalizedURLButtonClickTracking();
    dispatch(
      ON_SPONSOR_GIFT({ payload, googleAnanlyticsPurchaseEventPayload })
    );
  };

  useEffect(() => {
    // We only change this state back if there is an error in payment processing. This
    // will allow the user to edit payment information after the saga prevents the reroute
    // to the exit 'thank you' screen. 
    if (isPaymentError) {
      setIsPaymentProcessing(false);
    }
  }, [isPaymentError]);

  const websiteLink = (
    <a
      href="https://www.prisonfellowship.org/resources/privacy-policy/"
      target="_blank"
      rel="noreferrer"
    >
      <u>website</u>
    </a>
  );
  const phoneLink = isMobileLayout ? (
    <a style={{ color: 'DarkSlateGrey' }} href="tel:8002069764">
      800.206.9764
    </a>
  ) : (
    '800.206.9764'
  );
  const isPaymentProcessingStyle = isPaymentProcessing
    ? { cursor: 'wait' }
    : { cursor: '' };

  const renderPaymentBody = () => {
    return (
      <>
        {isMobileLayout && subHeaderImage}
        <div
          style={isPaymentProcessingStyle}
          className="payment-body-container"
        >
          <div className="header-text-container">
            <p>Yes, I want to bless children of prisoners this Christmas!</p>
          </div>
          <div className="payment-information-container">
            <p className="payment-information-container__header">
              PAYMENT INFORMATION
            </p>
            <p className="payment-information-container__method">
              Payment Method*
            </p>
            <div className="payment-information-container__button-container">
              <button
                disabled={isPaymentProcessing}
                style={isPaymentProcessingStyle}
                className={showCCInputs ? 'cc-button' : ''}
                onClick={showCCInputs ? undefined : () => onSelectCCTab(true)}
              >
                <div className="image-container">
                  <img
                    className="image-size"
                    src={CreditButton}
                    alt={`Credit Button`}
                  />
                </div>
              </button>
              <button
                disabled={isPaymentProcessing}
                style={isPaymentProcessingStyle}
                className={!showCCInputs ? 'bank-button' : ''}
                onClick={showCCInputs ? () => onSelectCCTab(false) : undefined}
              >
                <div className="image-container">
                  <img
                    className="image-size"
                    src={BankButton}
                    alt={`Bank Buton`}
                  />
                </div>
              </button>
            </div>
            {showCCInputs && (
              <div className="payment-information-container__cc-form-field">
                <input
                  disabled={isPaymentProcessing}
                  style={isPaymentProcessingStyle}
                  placeholder="Credit Card Number*"
                  onChange={handleInputs}
                />
                <div className="expiration-date-container">
                  <p>Expiration Date*</p>
                  <div className="expiration-date">
                    <select
                      disabled={isPaymentProcessing}
                      style={isPaymentProcessingStyle}
                      id="Month"
                      onChange={handleInputs}
                    >
                      {MONTHS_DROPDOWN_ITEMS.map((month) => (
                        <option value={month.value}>{month.label}</option>
                      ))}
                    </select>
                    <select
                      disabled={isPaymentProcessing}
                      style={isPaymentProcessingStyle}
                      id="Year"
                      onChange={handleInputs}
                    >
                      <option>Year*</option>
                      {expirationYears.map((year) => (
                        <option>{year}</option>
                      ))}
                    </select>
                  </div>
                  <input
                    disabled={isPaymentProcessing}
                    style={isPaymentProcessingStyle}
                    placeholder="CVV*"
                    onChange={handleInputs}
                  />
                </div>
              </div>
            )}
            {!showCCInputs && (
              <div className="payment-information-container__bank-account-form-field">
                <input
                  disabled={isPaymentProcessing}
                  style={isPaymentProcessingStyle}
                  placeholder="Routing Number*"
                  onChange={handleInputs}
                />
                <input
                  disabled={isPaymentProcessing}
                  style={isPaymentProcessingStyle}
                  placeholder="Account Number*"
                  onChange={handleInputs}
                />
                <input
                  disabled={isPaymentProcessing}
                  style={isPaymentProcessingStyle}
                  placeholder="Confirm Account Number*"
                  onChange={handleInputs}
                />
                <select
                  style={isPaymentProcessingStyle}
                  id="Account Type"
                  onChange={handleInputs}
                >
                  {accountTypes.map((type) => (
                    <option>{type.label}</option>
                  ))}
                </select>
                <input
                  disabled={isPaymentProcessing}
                  style={isPaymentProcessingStyle}
                  placeholder="Bank Name*"
                  onChange={handleInputs}
                />
                <input
                  disabled={isPaymentProcessing}
                  style={isPaymentProcessingStyle}
                  placeholder="Account Name*"
                  onChange={handleInputs}
                />
              </div>
            )}
          </div>
          <div className="user-information-container">
            <p className="payment-information-container__header">
              YOUR INFORMATION
            </p>
            <div className="user-information-container__user-info">
              <div className="user-information-container__user-info__name">
                <input
                  disabled={isPaymentProcessing}
                  style={isPaymentProcessingStyle}
                  placeholder="First Name*"
                  onChange={handleInputs}
                />
                <input
                  disabled={isPaymentProcessing}
                  style={isPaymentProcessingStyle}
                  placeholder="Last Name*"
                  onChange={handleInputs}
                />
              </div>
              <input
                disabled={isPaymentProcessing}
                style={isPaymentProcessingStyle}
                placeholder="Email Address*"
                onChange={handleInputs}
              />
              <input
                disabled={isPaymentProcessing}
                style={isPaymentProcessingStyle}
                placeholder="Address*"
                onChange={handleInputs}
              />
              <input
                disabled={isPaymentProcessing}
                style={isPaymentProcessingStyle}
                placeholder="Address Line 2"
                onChange={handleInputs}
              />
              <input
                disabled={isPaymentProcessing}
                style={isPaymentProcessingStyle}
                placeholder="City*"
                onChange={handleInputs}
              />
              <select
                disabled={isPaymentProcessing}
                style={isPaymentProcessingStyle}
                id="State/Province*"
                onChange={handleInputs}
              >
                <option>State/Province*</option>
                {dropdownStates.map((state) => (
                  <option value={state.value}>{state.label}</option>
                ))}
              </select>
              <input
                disabled={isPaymentProcessing}
                style={isPaymentProcessingStyle}
                className="user-information-container__user-info__zipcode"
                placeholder="Zip/Postal Code*"
                onChange={handleInputs}
              />
            </div>
          </div>
          {!isNationalOrg && (
            <FormGroup className="donation-entity-container">
              <PfCheckbox
                isDisabled={isPaymentProcessing}
                isChecked={
                  donationEntityTypeId === DonationEntityTypeId.ORGANIZATION
                }
                onChange={(_, checked) => {
                  checked
                    ? setDonationEntityTypeId(DonationEntityTypeId.ORGANIZATION)
                    : setDonationEntityTypeId(DonationEntityTypeId.PERSON);
                }}
                label={
                  'I am a church staff member or employee donating with a church account/credit card.'
                }
              />
            </FormGroup>
          )}
          <div className="payment-footer-container">
            <div className="donate-button-container">
              <div className="donation-warning">
                <p>
                  <IoMdLock style={{ paddingBottom: '1vh' }} size={24} />
                  By clicking DONATE your <b>one-time</b> donation in the amount
                  of <b>${donationTotal}</b> will be securely processed.
                </p>
              </div>
              <div className="donation-button">
                {isPaymentProcessing ? (
                  <p>Processing Payment...</p>
                ) : (
                  <Button
                    isDisabled={isPaymentProcessing}
                    styleOverride={{
                      width: '75vw',
                      height: '5vh',
                      fontSize: '20px',
                      fontFamily: 'Oswald',
                      cursor: isPaymentProcessing ? 'wait' : '',
                    }}
                    onClick={validatePayment}
                  >
                    DONATE
                  </Button>
                )}
              </div>
              <div className="secure-donation-image-container">
                <img src={SecureDonation} alt={`Secure Donation`} />
              </div>
              <div className="how-to-donate-container">
                <p className="donation-text">
                  DONATE BY PHONE: {phoneLink} <br /> (M-F 8:30 am - 5:00 pm ET)
                </p>
                <p className="donation-text">
                  DONATE BY MAIL: Prison Fellowship, PO Box 1550, Merrifield, VA
                  22116
                </p>
              </div>
            </div>
            <div className="financial-accountability-container">
              <p className="titles">FINANCIAL ACCOUNTABILITY</p>
              <p>
                Prison Fellowship® complies fully with federal and state
                charitable solicitation requirements and is a member of the
                Evangelical Council for Financial Accountability.
              </p>
              <div className="accountability-assets">
                <img
                  className="guidestar"
                  src={GuidestarPlatinumSeal}
                  alt={`Financial Accountability Assets`}
                />
                <img
                  className="ecfa"
                  src={EcfaAccreditedSeal}
                  alt={`Financial Accountability Assets`}
                />
                <img
                  className="accredited-charity"
                  src={AccreditedCharitySeal}
                  alt={`Financial Accountability Assets`}
                />
              </div>
              <p className="titles">PRISON FELLOWSHIP'S PRIVACY POLICY</p>
              <p>
                Prison Fellowship values your privacy. To learn more visit our{' '}
                {websiteLink}.
              </p>
              <p>
                Prison Fellowship® is a 501(c)(3) organization, gifts to which
                are deductible to the full extent allowable under the law.
                Federal tax ID#: 62-0988294.
              </p>
              <p>
                UNIFORM DISCLOSURES are printed or are available on our{' '}
                {websiteLink}.
              </p>
              <div className="accountability-assets__cfc-image">
                <img src={CfcSeal} alt={`Financial Accountability Assets`} />
              </div>
              <p>
                Prison Fellowship® is an approved charity of the Combined
                Federal Campaign (CFC #11272)
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isMobileLayout ? (
        renderPaymentBody()
      ) : (
        <div className="desktop-payment-container">
          {desktopHeaderImage}
          <div className="desktop-payment-container__floating-container">
            {renderPaymentBody()}
          </div>
        </div>
      )}
    </>
  );
};

export default Payment;
